import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import BTE from 'lib/BTE';
import Breakpoints from 'lib/Breakpoints';

import LeftRailLayout from 'components/layouts/LeftRail';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import LayoutContext from 'components/layouts/LayoutContext';

import styles from './styles.module.scss';

/**
 * Creates a oneUp package
 * @param {Array<object>} items - The items to include in the package
 */
const oneUp = (items) => ({
  id: 'bestOfOneUp',
  name: null,
  type: 'oneUp',
  zone: 2,
  metadata: {},
  autofill: {},
  items,
});
/**
 * Creates a twoUp package
 * @param {Array<object>} items - The items to include in the package
 */
const twoUp = (items) => ({
  id: 'bestOfTwoUp',
  name: null,
  type: 'twoUp',
  zone: 2,
  metadata: {},
  autofill: null,
  items,
});
/**
 * Creates a threeUp package
 * @param {Array<object>} items - The items to include in the package
 */
const threeUp = (items) => ({
  id: 'bestOfThreeUp',
  name: null,
  type: 'threeUp',
  zone: 2,
  metadata: {},
  autofill: null,
  items,
});
/**
 * Implements the BestOfPlaylist page and content.
 * @param {object} props - props object
 * @param {string} props.title - The title of the playlist
 * @param {object} props.playlist - The playlist object
 * @param {string} props.vertical - The vertical of the playlist
 * @returns {JSX.Element} The rendered BestOfPlaylist component
 */
const BestOfPlaylist = ({
  title,
  playlist,
  vertical,
}) => {
  const [commonLayout, setCommonLayout] = useState(true);
  /**
   * Monitor the breakpoint and set the common layout
   */
  const monitorBreakpoint = () => {
    const isXL = Breakpoints.isXL();

    if (commonLayout && isXL) {
      setCommonLayout(false);
    } else if (!commonLayout && !isXL) {
      setCommonLayout(true);
    }
  };

  useEffect(() => {
    BTE.on('resize', monitorBreakpoint);

    return () => {
      BTE.remove('resize', monitorBreakpoint);
    };
  });

  const isMSNBC = vertical === 'msnbc';
  const mbtVideos = playlist.videos
    // TODO: remove sort in Bento when API returns sorted playlists
    .sort((a, b) => new Date(b.datePublished) - new Date(a.datePublished))
    // Only process what is needed (playlist can have 100 videos)
    .slice(0, 6)
    // Format
    .map((video) => ({
      type: 'video',
      id: video.id,
      item: {
        ...video,
      },
      metadata: {},
      computedValues: {
        headline: video.headline?.primary,
        dek: video.description?.primary,
        unibrow: video.unibrow,
        url: video.url?.primary,
        teaseImage: {
          ...video.teaseImage,
          url: video.teaseImage?.url,
        },
      },
    }));

  const url = `${mbtVideos[0].item.url?.primary}?playlist=${playlist.id}`;

  const titlePkg = {
    id: 'bestOfTitle',
    name: `${i18next.t('Best Of')} ${vertical[0].toUpperCase() + vertical.slice(1)}`,
    type: 'collectionTitle',
    zone: 1,
    metadata: {
      title,
      seeAllUrl: url,
      seeAllText: i18next.t('Play All'),
    },
  };

  const ad = {
    id: 'bestOfAd',
    name: 'bestOfAd',
    type: 'ad',
    zone: 1,
    metadata: {},
  };

  const packages = [titlePkg, ad];
  if (commonLayout) {
    // TwoUp, ThreeUp, OneUp (all but 1240+)
    packages.push(
      twoUp(mbtVideos.slice(0, 2)),
      threeUp(mbtVideos.slice(2, 5)),
      oneUp([mbtVideos[5]]),
    );
  } else {
    // TwoUp, OneUp, ThreeUp (1240+)
    packages.push(
      twoUp(mbtVideos.slice(0, 2)),
      oneUp([mbtVideos[2]]),
      threeUp(mbtVideos.slice(3)),
    );
  }

  return (
    <div
      className="layout-grid-container mv8 best-of-playlist"
      data-testid="best-of-playlist"
    >
      <LayoutContext
        layoutType={null}
        vertical={vertical}
      >
        <LeftRailLayout packages={packages} />
      </LayoutContext>
      <ButtonHoverAnimation
        additionalClasses={styles.bottomButton}
        hasDarkBackground={isMSNBC}
        title="Play All"
        type="link"
        url={url}
      />
    </div>
  );
};

export default BestOfPlaylist;
