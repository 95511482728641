import React, { useContext } from 'react';
import { stub as $t } from '@nbcnews/analytics-framework';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { videoPlaylist as playlistPropType } from 'lib/CustomPropTypes';
import { BreakpointContext } from 'lib/ContextTypes';
import { useCarousel } from 'lib/Hooks/useCarousel';

import Playlist from './Playlist';

import styles from './styles.module.scss';

export const LAYOUT = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

const PlaylistDrawer = ({
  layout = LAYOUT.VERTICAL,
  playlists = [],
  nowPlaying = null,
  onSelect = Function.prototype,
  vertical = null,
}) => {
  const { isS, isM } = useContext(BreakpointContext);
  const {
    ref: playlistRef,
    next,
    prev,
    hasPrev,
    hasNext,
  } = useCarousel({
    disabled: vertical !== 'today',
    direction: isS || isM ? 'horizontal' : 'vertical',
    loop: true,
  });

  $t('register', 'mbp_playlistDrawer_click', { allowDuplicate: true });

  const setPlaylistClickTracking = (object) => {
    $t('track', 'mbp_playlistDrawer_click', object);
  };

  return (
    <>
      <button className={classNames(styles.prevButton, { [styles.disabled]: !hasPrev })} type="button" onClick={prev} data-testid="prev-button">
        <span className="icon icon-back" />
      </button>
      <div className={styles.playlistDrawer} ref={playlistRef} data-testid="playlist-drawer">
        {playlists && playlists.map((playlist, index) => (
          <Playlist
            playlist={playlist}
            nowPlaying={nowPlaying}
            layout={layout}
            onSelect={onSelect}
            trackLink={setPlaylistClickTracking}
            allowCollapse={layout === LAYOUT.HORIZONTAL}
            collapsedByDefault={index !== 0}
            key={(playlist?.id) || `recommendations${index}`}
            vertical={vertical}
          />
        ))}
      </div>
      <button className={classNames(styles.nextButton, { [styles.disabled]: !hasNext })} type="button" onClick={next} data-testid="next-button">
        <span className="icon icon-back" />
      </button>
    </>
  );
};

PlaylistDrawer.propTypes = {
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  playlists: PropTypes.arrayOf(playlistPropType),
  nowPlaying: PropTypes.string,
  onSelect: PropTypes.func,
  vertical: PropTypes.string,
};


export default PlaylistDrawer;
