import React, { useContext } from 'react';
import classNames from 'classnames';
import i18next from 'i18next';

import { VerticalContext } from 'lib/ContextTypes';
import { formatDuration } from 'lib/DateTime';
import { imageAltForImage } from 'lib/imageAlt';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { REMOVE_NOW_PLAYING_VIDEO_FROM_PLAYLIST } from 'lib/brandFeatures';
import { isVideoLive } from 'lib/videoUtils';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { Picture } from 'components/Picture';

import './styles.themed.scss';

/**
 * @typedef {import('lib/graphqlTypeDefs').Video} Video
 * @typedef {import('lib/graphqlTypeDefs').VideoPlaylist} VideoPlaylist
 */

/**
 * @typedef {object} VideoProps
 * @property {Array<Video>} video
 * @property {bool} [selected]
 * @property {Function} [onClick]
 * @property {number} [playlistIndex]
 * @property {VideoPlaylist} [playlist]
 * @property {boolean} [upNext]
 */

/**
 * This is the video component that appears in the playlist drawer
 * @param {VideoProps} props
 */
const Video = ({
  video,
  selected = false,
  onClick = Function.prototype,
  playlistIndex = 0,
  playlist = {},
  upNext = false,
}) => {
  const vertical = useContext(VerticalContext);

    /**
   * @param {event} event
   *  This is the click handler for the video component
   */
  const clickHandler = (event) => {
    event.preventDefault();
    onClick(video, playlistIndex, playlist, 'manual');
    window.scrollTo(0, 0);
  };

  const { primaryImage, duration, headline } = video;
  const isLiveVideo = isVideoLive(video);

  if (getFeatureConfigForBrand(REMOVE_NOW_PLAYING_VIDEO_FROM_PLAYLIST, vertical) && selected) {
    return null;
  }

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      className="playlist-drawer__video"
      onClick={clickHandler}
      data-testid="playlist-drawer-video"
    >
      {upNext && <div className="playlist-drawer__up-next">{i18next.t('UP NEXT')}</div>}
      <div
        className="playlist-drawer__thumbnail"
      >
        {selected && <div className="playlist-drawer__active" data-testid="playlist-active">{i18next.t('Now Playing')}</div>}
        {isLiveVideo
          && (
            <span className="playlist-drawer__flag">
              <InlineBlinkingLiveDot className="playlist-drawer__flag-dot" />
              {i18next.t('Live')}
            </span>
          )}
        <Picture
          url={primaryImage.url.primary}
          className={classNames('playlist-drawer__thumbnail')}
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_520x260,
            m: AIMS_FLAVORS.FOCAL_760x380,
            l: AIMS_FLAVORS.FOCAL_280x140,
          }}
          alt={imageAltForImage(primaryImage)}
          lazyloaded // Related thumbnails are never Largest Contentful Paint
        />
      </div>
      <div className={classNames('playlist-drawer__info', 'pl4-l pr6-l pa5-l')}>
        <h2 className={classNames('playlist-drawer__title')} data-testid="playlist-title">
          {headline.tease}
        </h2>
        <span className={classNames('playlist-drawer__duration', 'founders-mono')} data-testid="playlist-duration">
          {formatDuration(duration)}
        </span>
      </div>
    </div>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events */
  /* eslint-enable jsx-a11y/no-static-element-interactions */
};

export default Video;
