import { useRef, useState, useEffect } from 'react';

/**
 * Get the index of the first visible item in the array.
 * @param {boolean[]} arr - Array of boolean values indicating visibility.
 * @returns {number} - Index of the first visible item.
 */
function getIndexOfFirstVisibleItem(arr) {
  return arr.findIndex((value) => value === true);
}

/**
 * Get the index of the last visible item in the array.
 * @param {boolean[]} arr - Array of boolean values indicating visibility.
 * @returns {number} - Index of the last visible item.
 */
function getIndexOfLastVisibleItem(arr) {
  return arr.findLastIndex((value) => value === true);
}

/**
 * @type {CarouselOptions}
 */
const defaultOptions = {
  direction: 'horizontal',
  childrenSelector: 'li',
  disabled: false,
  loop: false,
};

/**
 * @typedef {object} CarouselValues
 * @property {object} ref - Reference to the carousel container
 * @property {Function} next - Scroll next items into view
 * @property {Function} prev - Scroll prev items into view
 * @property {boolean} hasNext - True if there are next items
 * @property {boolean} hasPrev - True if there are previous items
 */

/**
 * @typedef {object} CarouselOptions
 * @property {string} direction - Direction of the carousel (horizontal|vertical)
 * @property {string} childrenSelector - Selector for the children elements (e.g. 'li')
 * @property {boolean} disabled - Disable carousel
 * @property {boolean} loop - Go to the beginning/end when reaching the end/beginning
 */

/**
 * Hook to build a logic arround carousel.
 * @param {CarouselOptions} options - Carousel options
 * @returns {CarouselValues} - Object
 *
 */
export function useCarousel(options = {}) {
  const {
    direction,
    childrenSelector,
    disabled,
    loop,
  } = { ...defaultOptions, ...options };

  const ref = useRef(null);
  const children = useRef(null);

  const indexOfFirstVisibleItem = useRef(-1);
  const indexOfLastVisibleItem = useRef(-1);
  const numberOfVisbibleItems = useRef(0);

  const childrenVisibility = useRef([]);

  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);

  /**
   * Scroll to a specific element within the carousel.
   * @param {HTMLElement} element - The element to scroll to.
   * @param {object} [options] - Scroll options.
   * @param {string} [options.inline='start'] - Scroll alignment (start|end).
   */
  function scrollTo(element, { inline = 'start' } = {}) {
    const position = { top: element.offsetTop, left: element.offsetLeft };

    if (direction === 'horizontal' && inline === 'end') {
      position.left = element.offsetLeft - ref.current.offsetWidth;
    }

    if (direction === 'vertical' && inline === 'end') {
      position.top = element.offsetTop - ref.current.offsetHeight;
    }

    /**
     * Note: Unfortunately scrollIntoView does work with multiple scroll containers.
     * Also scrollIntoView scrolls the body wich might be not what we want.
     */
    ref.current.scrollTo({
      ...position,
      behavior: 'smooth',
    });
  }

  /**
   * Go to a specific index in the carousel.
   * @param {number} index - The index to go to.
   * @param {object} scrollToOptions - Options for scrolling.
   */
  function goto(index, scrollToOptions) {
    if (!disabled) {
      const nextIndex = Math.max(Math.min(index, children.current.length - 1), 0);
      scrollTo(children.current[nextIndex], scrollToOptions);
    }
  }

  /**
   * Scroll to the next set of items in the carousel.
   */
  function next() {
    if (!disabled) {
      let nextIndex = indexOfLastVisibleItem.current + 1;
      if (loop && !hasNext) nextIndex = 0;
      goto(nextIndex, { inline: 'start' });
    }
  }

  /**
   * Scroll to the previous set of items in the carousel.
   */
  function prev() {
    if (!disabled) {
      let nextIndex = indexOfFirstVisibleItem.current;
      let scrollToOptions = { inline: 'end' };
      if (!hasPrev) scrollToOptions = { inline: 'start' };
      if (loop && !hasPrev) nextIndex = children.current.length - 1;
      goto(nextIndex, scrollToOptions);
    }
  }

  /**
   * Bind Intersection Observer
   */
  useEffect(() => {
    if (ref.current && !disabled) {
      children.current = Array.from(ref.current?.querySelectorAll(childrenSelector));
      childrenVisibility.current = new Array(children.current.length).fill(false);

      const firstItem = children.current.at(0);
      const lastItem = children.current.at(-1);

      /**
       * Intersection Observer callback.
       * @param {IntersectionObserverEntry[]} entries - Array of intersection observer entries.
       */
      const cb = (entries) => {
        entries.forEach((entry) => {
          if (entry.target === firstItem) setHasPrev(entry.intersectionRatio < 1);
          if (entry.target === lastItem) setHasNext(entry.intersectionRatio < 1);

          const indexOfEntry = children.current.indexOf(entry.target);
          childrenVisibility.current[indexOfEntry] = entry.intersectionRatio >= 1;
        });

        indexOfFirstVisibleItem.current = getIndexOfFirstVisibleItem(
          childrenVisibility.current,
        );
        indexOfLastVisibleItem.current = getIndexOfLastVisibleItem(
          childrenVisibility.current,
        );
        numberOfVisbibleItems.current = indexOfLastVisibleItem.current
          - indexOfFirstVisibleItem.current + 1;
      };

      const observer = new IntersectionObserver(cb, {
        root: ref.current,
        rootMargin: '0px',
        threshold: 1.0,
      });

      children.current.forEach((item) => observer.observe(item));

      return () => {
        children.current.forEach((item) => observer.unobserve(item));
        observer.disconnect();
      };
    }
    return undefined;
  }, [disabled]);

  return {
    ref,
    next,
    prev,
    goto,
    hasNext,
    hasPrev,
  };
}
