import React, { useEffect } from 'react';
import { REORDER_PLAYLIST_SO_NEXT_VIDEO_IS_ALWAYS_AT_TOP } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import classNames from 'classnames';
import { forceCheck } from 'react-lazyload';

import Video from '../Video';

import styles from './styles.module.scss';

export const LAYOUT = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

/**
 * @typedef {import('lib/graphqlTypeDefs').VideoPlaylist} VideoPlaylist
 */

/**
 * @typedef {object} VideoDrawerPlaylistProps
 * @property {Array<VideoPlaylist>} playlist
 * @property {string} [nowPlaying]
 * @property {Function}[onSelect]
 * @property {'vertical' | 'horizontal'} [layout]
 * @property {boolean} [allowCollapse]
 * @property {boolean} [collapsedByDefault]
 * @property {string} [vertical]
 * @property {Function} [trackLink]
 */

/**
* This is the playlist component that appears under the video player
* @param {VideoDrawerPlaylistProps} props
*/
const VideoDrawerPlaylist = ({
  playlist,
  nowPlaying = null,
  onSelect = Function.prototype,
  layout = LAYOUT.VERTICAL,
  allowCollapse = false,
  collapsedByDefault = false,
  vertical = null,
  trackLink = () => {},
}) => {
  const collapsed = allowCollapse && collapsedByDefault;

  useEffect(() => {
    if (getFeatureConfigForBrand(REORDER_PLAYLIST_SO_NEXT_VIDEO_IS_ALWAYS_AT_TOP, vertical)) {
      // Check lazyloaded elements that may have entered the viewport when playlist gets reordered
      forceCheck();
    }
  }, []);

  /**
 * @param {number} index
 * @param {object} prevVideo This is the previous video object in the playlist
 * @param {object} currVideo
 *  This is the video object of the current video in the iteration of the playlist
 */
  const upNext = ({
    index, prevVideo, currVideo,
  }) => {
    if (playlist) {
      if (prevVideo && prevVideo.id === nowPlaying) return true;
      if (
        index === 0
        && currVideo.id !== nowPlaying
        && Array.isArray(playlist.videos)
        // playlist doesn't include currently playing video
        && !playlist.videos.some((v) => v.id === nowPlaying)
      ) {
        return true;
      }
    }

    return false;
  };

  const videos = (playlist?.videos) ? playlist.videos : null;

  if (videos) {
    if (getFeatureConfigForBrand(REORDER_PLAYLIST_SO_NEXT_VIDEO_IS_ALWAYS_AT_TOP, vertical)) {
      const currentVideoIndex = videos.findIndex((video) => video.id === nowPlaying);
      if (currentVideoIndex > -1) {
        // Reorder playlist so current video is at the beginning
        videos.unshift(...videos.splice(currentVideoIndex));
      }
    }
  }

  const className = classNames(styles.playlist, styles[layout], {
    [styles.collapsed]: collapsed,
    [styles.collapsible]: allowCollapse,
    [styles.recommendations]: playlist?.recommendations,
  });

  return (
    <section className={className}>
      {videos && (
        <ul className={styles.videos}>
          {videos.map((video, index) => (
            <li key={video.mpxMetadata.id || video.id} className={styles.video}>
              <Video
                video={video}
                onClick={(...args) => {
                  trackLink({
                    action: 'videoClick',
                    id: video.id,
                    title: video.headline?.primary,
                  });
                  onSelect(...args);
                }}
                playlistIndex={index}
                playlist={playlist}
                selected={video.id === nowPlaying}
                upNext={upNext({
                  playlist, index, prevVideo: videos[index - 1], currVideo: video, nowPlaying,
                })}
              />
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default VideoDrawerPlaylist;
