import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { setPageView } from 'redux/modules/shared';
import {
  loadVideo as loadVideoAction,
  setCurrent as setCurrentAction,
} from 'redux/modules/video';
import { loadRelatedArticle as loadRelatedArticleAction } from 'redux/modules/video/relatedArticle';
import { loadRelatedVideo as loadRelatedVideoAction } from 'redux/modules/video/relatedVideo';
import { loadTopPlaylist } from 'redux/modules/video/topPlaylists';
import {
  loadAssociatedPlaylist as loadAssociatedPlaylistAction,
  loadVideosByIds as loadVideosByIdsAction,
} from 'redux/modules/video/associatedPlaylists';

import {
  MOBILE_PLAYLIST_NUMBER_OF_VIDEOS,
  MOBILE_CAROUSEL_DRAWER,
  HAS_BACON_VIDEO_PLAYLISTS,
} from 'lib/brandFeatures';
import { useFeatureFlagContext } from 'lib/ContextTypes';

import BTE from 'lib/BTE';
import buildUrl from 'lib/buildUrl';
import determineDomain from 'lib/determineDomain';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import isVerticalVideo from 'lib/isVerticalVideo';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { parseIdFromUrl } from 'lib/parseIdFromUrl';
import parseQueryString from 'lib/parseQuerystring';
import { stub as $t } from '@nbcnews/analytics-framework';
import { parseOnClient } from 'lib/urlUtils';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { formatPlaylistDataForBacon } from 'lib/baconHelpers';

import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import BestOfPlaylist from 'components/BestOfPlaylist';
import { Bacon } from 'components/packages/Bacon';
import { ErrorBoundary } from 'components/ErrorBoundary';
import PlaylistDrawer, { LAYOUT } from 'components/PlaylistDrawer';
import Playlister from 'components/Playlister';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { GlobalMetadata, VideoMetadata } from 'components/PageMetadata';
import ScrollingAnalytics from 'components/ScrollingAnalytics';
import Taboola from 'components/Taboola';
import VideoDetails from 'components/VideoDetails';
import VideoPlayer from 'components/VideoPlayer';
import Ad from 'components/Ad';
import IconfontStyleTag from 'components/IconfontStyleTag';
import UniversalCheckout from 'components/UniversalCheckout';
import { CommerceAmazonContentInsights } from 'components/Commerce/AmazonContentInsights';

import { PrimaryMediaBanner } from 'components/PrimaryMediaBanner';
import { logAction, logError } from 'lib/datadog';
import ErrorPage from '../_error';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import './styles.themed.scss';
import globalContainerStyles from '../globalContainerStyles.module.scss';

const pageView = 'video';

/**
 * @typedef {import('lib/graphqlTypeDefs').Video} Video
 * @typedef {import('lib/graphqlTypeDefs').VideoPlaylist} VideoPlaylist
 * @typedef {import('lib/graphqlTypeDefs').socialMediaProfiles} socialMediaProfiles
 */

/**
 * @template T
 * @param {Array<T>} order
 * @param {keyof T} property
 */
export const sortArrayByList = (order, property) => (a, b) => order.indexOf(a[property]) - order.indexOf(b[property]);

/**
 * Limits the number of videos in each top playlist
 * @param {object} args
 * @param {Array<VideoPlaylist>} args.topPlaylists
 * @param {number} args.limit
 * @returns {Array<VideoPlaylist>} topPlaylists with limited number of videos
 */
export function limitSizeOfTopPlaylists({ topPlaylists, limit }) {
  if (!Number.isInteger(limit)) return topPlaylists;
  return topPlaylists.map((playlist) => ({
    ...playlist,
    videos: playlist.videos.slice(0, limit),
  }));
}

const brandsWithTaboolaFeed = ['news', 'today', 'msnbc', 'mach', 'better', 'think', 'noticias'];

export const VERT_TOP_PLAYLISTS = {
  today: ['mmlsnnd_55339009-nnd', 'mmlsnnd_today3rdhour-nnd', 'mmlsnnd_26316687-nnd', 'mmlsnnd_shoptoday', 'mmlsnnd_34846743-nnd'],
  news: ['mmlsnnd_bestofnbc'],
  mach: ['mmlsnnd_mach'],
  better: ['mmlsnnd_better'],
  think: ['mmlsnnd_think'],
  msnbc: ['mmlsnnd_3096433-nnd'],
};

export const TITLE_FOR_PLAYLIST_ID = {
  'mmlsnnd_55339009-nnd': 'Latest Videos',
  'mmlsnnd_today3rdhour-nnd': '3rd Hour of TODAY',
  'mmlsnnd_26316687-nnd': 'Jenna & Friends',
  mmlsnnd_shoptoday: 'Shop the Show',
  'mmlsnnd_34846743-nnd': 'Recipes from the Show',
};

export const navbarConfig = {
  /**
   *
   * @param {object} args
   * @param {object} args.content
   * @param {string} args.vertical
   */
  theme: ({ content, vertical }) => {
    if (content.current?.error) {
      switch (vertical) {
        case 'today':
          return NAVBAR_THEME.LIGHT;
        default:
          return NAVBAR_THEME.VERTICAL;
      }
    }
    switch (vertical) {
      case 'telemundo':
      case 'entretenimiento':
      case 'shows':
        return NAVBAR_THEME.VERTICAL;
      default:
        return NAVBAR_THEME.DARK;
    }
  },
};

/**
 *
 * @param {object} args
 * @param {Video}  args.video
 * @param {object} args.shared
 */
const mapStateToProps = ({
  video,
  shared,
}) => ({
  associatedPlaylists: video.associatedPlaylists,
  isChromeless: shared.isChromeless,
  nowPlaying: video.current,
  relatedArticle: video.relatedArticle,
  relatedVideo: video.relatedVideo,
  socialMediaProfiles: shared.socialMediaProfiles,
  topPlaylists: limitSizeOfTopPlaylists({ topPlaylists: video.topPlaylists, limit: 10 }),
});

/**
 *
 * @param {Function} dispatch
 */
const mapActionsToProps = (dispatch) => ({
  /**
   *
   * @param {Video} video
   * @param {string} vertical
   */
  loadVideo: (video, vertical) => dispatch(loadVideoAction(video, vertical)),
  /**
   *
   * @param {number} playlistID
   */
  loadAssociatedPlaylist: (playlistID) => dispatch(loadAssociatedPlaylistAction(playlistID)),
  /**
   *
   * @param {Array<number>} ids
   */
  loadVideosByIds: (ids) => dispatch(loadVideosByIdsAction(ids)),
  /**
   *
   * @param {number} id
   */
  loadRelatedArticle: (id) => dispatch(loadRelatedArticleAction(id)),
  /**
   *
   * @param {number} id
   */
  loadRelatedVideo: (id) => dispatch(loadRelatedVideoAction(id)),
  /**
   *
   * @param {Video} video
   */
  setCurrent: (video) => dispatch(setCurrentAction(video)),
});

$t('register', 'mbt_video_drawer_click');

/**
 * @typedef {object} relatedVideoArticle
 * @property {object} headline
 * @property {string} headline.primary
 * @property {object} description
 * @property {string} description.primary
 * @property {boolean} ecommerceEnabled
 * @property {string} url
 */

/**
 * @typedef {object} servicesLayout
 * @property {object} header
 * @property {object} header.css
 * @property {Array<string>} header.css.link
 * @property {object} header.js
 * @property {Array<string>} header.js.html
 * @property {Array<string>} header.js.src
 * @property {Array<string>} header.html
 * @property {object} footer
 * @property {object} footer.css
 * @property {Array<string>} footer.css.link
 * @property {object} footer.js
 * @property {Array<string>} footer.js.html
 * @property {Array<string>} footer.js.src
 * @property {Array<string>} footer.html
 */

/**
 * @typedef {object} VideoPageProps
 * @property {Array<Video>} [VideoPageProps.associatedPlaylists]
 * @property {boolean} VideoPageProps.isChromeless
 * @property {servicesLayout} VideoPageProps.layout
 * @property {Function} [VideoPageProps.loadAssociatedPlaylist]
 * @property {Function} [VideoPageProps.loadRelatedArticle]
 * @property {Function} [VideoPageProps.loadRelatedVideo]
 * @property {Function} VideoPageProps.loadVideo
 * @property {Function} [VideoPageProps.loadVideosByIds]
 * @property {Video} VideoPageProps.nowPlaying
 * @property {relatedVideoArticle} VideoPageProps.relatedArticle
 * @property {relatedVideoArticle} VideoPageProps.relatedVideo
 * @property {Function} VideoPageProps.setCurrent
 * @property {socialMediaProfiles} VideoPageProps.socialMediaProfiles
 * @property {number} VideoPageProps.statusCode
 * @property {Array<VideoPlaylist>} [VideoPageProps.topPlaylists]
 * @property {string} VideoPageProps.vertical
 */

/**
* Video page component
* @param {VideoPageProps} props
*/
const VideoPage = ({
  associatedPlaylists = [],
  isChromeless,
  layout,
  loadAssociatedPlaylist = Function.prototype,
  loadRelatedArticle = Function.prototype,
  loadRelatedVideo = Function.prototype,
  loadVideo,
  loadVideosByIds = Function.prototype,
  nowPlaying,
  relatedArticle,
  relatedVideo,
  setCurrent,
  socialMediaProfiles,
  statusCode,
  topPlaylists = [],
  vertical,
}) => {
  const [fetchPlaylistData, setFetchPlaylistData] = useState(false);
  const [enforceRatio, setEnforceRatio] = useState(false);
  const [nowPlayingInitiator, setNowPlayingInitiator] = useState('continuous');
  const autoplayTestGroup = useState([]);

  let playerWrapper;
  let drawerWrapper;

  /**
   * Handler for video resize event
   * @param {number} width
   * @param {number} height
   */
  const onResize = ({ width: ww, height: wh }) => {
    const {
      top,
      width: pw,
      height: ph,
    } = (playerWrapper && playerWrapper.getBoundingClientRect()) || {};

    if (top + ph > wh) {
      if (!enforceRatio) {
        // TODO (related to BENTO-10896): figure out what race condition (probably)
        // is causing `drawerWrapper` to be undefined sometimes when this method
        // is called. Adding a check around `playerWrapper` as well, to be safe
        if (playerWrapper && typeof playerWrapper.setAttribute === 'function') {
          playerWrapper.setAttribute(
            'style',
            `flex-grow:unset; margin:0 auto; width:calc((100vh - ${top}px)*(16/9)); height:calc(100vh - ${top}px)`,
          );
        }
        if (drawerWrapper && typeof drawerWrapper.setAttribute === 'function') {
          drawerWrapper.setAttribute('style', `height:calc(100vh - ${top}px);`);
        }
        setEnforceRatio(true);
      }
    } else if ((ww < 1000 && ww === pw) || (ww >= 1000 && ww <= pw + 465)) {
      if (enforceRatio) {
        if (playerWrapper && typeof playerWrapper.removeAttribute === 'function') {
          playerWrapper.removeAttribute('style');
        }
        if (drawerWrapper && typeof drawerWrapper.removeAttribute === 'function') {
          drawerWrapper.removeAttribute('style');
        }
        setEnforceRatio(false);
      }
    }
    logAction('video', 'videoResized');
  };

  /**
   * Handler for video play event, loads videos from the associated playlists
   */
  const onVideoPlay = () => {
    if (fetchPlaylistData) {
      const ids = associatedPlaylists[0].videos.map((video) => video.mpxMetadata.id);

      // Send request for Video-teases of multiple ids.
      loadVideosByIds(ids);
      setFetchPlaylistData(false);
    }
    logAction('video', 'videoPlayed');
  };

  /**
   * @param {boolean} selected
   * @param {number} playlistIndex
   * @param {Array<VideoPlaylist>} playlist
   * @param {string} initiator
   */
  const onVideoSelect = async (selected, playlistIndex, playlist, initiator) => {
    // Track at beginning, so it fires in both cases.
    $t('track', 'mbt_video_drawer_click', { video: selected, index: playlistIndex, playlist });
    logAction('videoSelected', { video: selected, index: playlistIndex, playlist });

    setNowPlayingInitiator(initiator);

    if (
      !isVerticalVideo(selected)
      && !determineDomain(selected.url.primary).match(
        /(nbcnews|today|msnbc|telemundo).com/i,
      )
    ) {
      window.location = selected.url.primary;
      return;
    }

    let url;

    if (!selected.id) {
      await loadVideo(selected.mpxMetadata.id, vertical);
      url = buildUrl(nowPlaying);
    } else {
      await setCurrent(selected);
      url = buildUrl(selected);
    }

    /*
     * Instead of routing, we'll update url for sharing.
     * Users most likely don't see this page as a seperate
     * pages and back button functionality will throw them off
     * so we replace state instead of push.
     */
    const parsedURL = parseOnClient(url);
    const normalizedURL = parsedURL.pathname || url;

    /**
     * We maintain the video page query parameters across
     * clicking through the playlists.
     */
    const { search: currentPageQueryParametersString } = window.location;
    const replacementURL = `${normalizedURL}${currentPageQueryParametersString}`;
    window.history.replaceState(null, null, replacementURL);
  };

  /**
   * Taboola component shows based off of provided vertical
   */
  const showTaboola = () => {
    if (brandsWithTaboolaFeed.includes(vertical)) {
      return <Taboola />;
    }
    return null;
  };

  /**
   * Fetches the related article or video from article id
   */
  const fetchRelatedArticle = () => {
    const relatedContentLink = nowPlaying?.relatedContentLink ?? { text: null, url: null };
    const relatedURL = relatedContentLink.url || associatedPlaylists[0].relatedUrl;
    if (!relatedURL) {
      return;
    }
    const relatedArticleId = parseIdFromUrl(relatedURL);
    if (!relatedArticleId) {
      return;
    }
    if (relatedArticleId.indexOf('mmvo') === 0 || relatedArticleId.indexOf('tmvo') === 0) {
      loadRelatedVideo(relatedArticleId);
    } else {
      loadRelatedArticle(relatedArticleId);
    }
  };

  /**
   * Updates playlist drawer to show current playlist items
   */
  const updatePlaylistDrawer = () => {
    // Playlist in path
    const queryParams = parseQueryString(window.location.search);
    if (queryParams?.playlist) {
      loadAssociatedPlaylist(queryParams.playlist);
    }
  };

  /**
   *
   */
  const drawer = () => (
    <div
      className="video-page__drawer"
      ref={(element) => { drawerWrapper = element; }}
    >
      <PlaylistDrawer
        playlists={associatedPlaylists}
        nowPlaying={nowPlaying.id}
        layout={LAYOUT.VERTICAL}
        onSelect={onVideoSelect}
        vertical={vertical}
      />
    </div>
  );

  useEffect(() => {
    if (statusCode === 200) {
      updatePlaylistDrawer();
      fetchRelatedArticle();

      const hasMobileCarouselDrawer = getFeatureConfigForBrand(MOBILE_CAROUSEL_DRAWER, vertical);

      if (!hasMobileCarouselDrawer) {
        BTE.monitorResize();
        BTE.on('resize', onResize, 'debounce');
        onResize({
          width: window.innerWidth || document.documentElement.clientWidth,
          height: window.innerHeight || document.documentElement.clientHeight,
        });
      }
    }
  }, []);

  /**
   * Shows BestOf playlist depending on vertical
   */
  const bestOf = () => {
    const showBestOf = vertical.match(/news|msnbc|today|mach|think|better/i);

    if (!showBestOf || !topPlaylists || !topPlaylists[0]) {
      return null;
    }

    if (vertical.match(/today|mach|think|better/i)) {
      // eslint-disable-next-line no-param-reassign
      topPlaylists[0].headline.primary = ['Best of ', `${vertical.toUpperCase()}`];
    }

    if (vertical === 'msnbc') {
      // eslint-disable-next-line no-param-reassign
      topPlaylists[0].headline.primary = topPlaylists[0].headline.primary.toUpperCase();
    }

    return (
      <BestOfPlaylist
        playlist={topPlaylists[0]}
        title={topPlaylists[0].headline.primary}
        vertical={vertical}
      />
    );
  };

  /**
   * Bacon menu component set by playlist as well as vertical
   */
  const bacon = () => topPlaylists.sort(sortArrayByList(VERT_TOP_PLAYLISTS[vertical], 'id')).map((playlist) => {
    const playlistTitle = TITLE_FOR_PLAYLIST_ID[playlist.id];
    const overwrites = playlistTitle ? { metadata: { title: playlistTitle } } : {};
    return (
      <Bacon
        key={playlist.id}
        content={formatPlaylistDataForBacon(playlist, overwrites)}
        vertical={vertical}
      />
    );
  });

  /**
   * @template T
   * @param {Array<T>} arr
   * @param {number} index
   */
  const insertAd = (arr, index) => {
    arr.splice(index, 0, (
      <div className="dn db-m">
        <Ad
          slot="topbanner"
          offsetViewport={100}
          pkgClassName="pkg-ad ad ad--centered"
          refreshInterval={0}
        />
      </div>));
    return arr;
  };

    /**
   * Main content error page
   */
  const handleMainContentError = () => (
    <ErrorPage
      statusCode={500}
    />
  );

  /**
   *
   * @param {Array<VideoPlaylist>} playlist
   */
  const computeMobilePlaylist = (playlist) => {
    const numberOfVideos = getFeatureConfigForBrand(MOBILE_PLAYLIST_NUMBER_OF_VIDEOS, vertical);
    const hasMobileCarouselDrawer = getFeatureConfigForBrand(MOBILE_CAROUSEL_DRAWER, vertical);

    if (!(playlist?.videos)) return { ...playlist, videos: null };
    return {
      ...playlist,
      videos: hasMobileCarouselDrawer ? playlist.videos : playlist.videos.slice(0, numberOfVideos),
    };
  };

  const { 'cta-canonical-video-page': primaryMediaBannerEnabled } = useFeatureFlagContext();

  if (parseInt(statusCode, 10) >= 400) {
    return (
      <ErrorPage
        statusCode={statusCode === 404 ? 404 : 500}
        vertical={vertical}
        layout={layout}
      />
    );
  }

  let relatedInfo = null;
  if (relatedVideo?.headline?.primary) {
    relatedInfo = { ...relatedVideo };
  } else {
    relatedInfo = { ...relatedArticle };
  }

  const relatedMPXLinkText = nowPlaying?.relatedContentLink?.text ?? null;
  const relatedMPXLinkURL = nowPlaying?.relatedContentLink?.url ?? null;
  const originalHeadline = relatedInfo?.headline ?? { primary: null };

  relatedInfo.description = relatedInfo?.description ?? null;
  relatedInfo.ecommerceEnabled = relatedInfo?.ecommerceEnabled ?? false;
  relatedInfo.headline = originalHeadline;
  relatedInfo.url = null;

  if (relatedMPXLinkText && relatedMPXLinkURL) {
    relatedInfo.headline.primary = relatedMPXLinkText;
    relatedInfo.url = relatedMPXLinkURL;
  }

  if (!relatedMPXLinkText && relatedInfo && relatedInfo.headline) {
    if (relatedInfo.headline.primary === null) {
      relatedInfo.headline = null;
    }
    relatedInfo.url = relatedMPXLinkURL;
  }

  const mobilePlaylist = [computeMobilePlaylist(associatedPlaylists[0])];
  const hasPlaylister = !vertical.match(/msnbc|news|today|mach|better|think/i);

  const shouldShowBacon = getFeatureConfigForBrand(HAS_BACON_VIDEO_PLAYLISTS, vertical);
  const shouldShowCTA = primaryMediaBannerEnabled && vertical === 'news';

  const primaryMediaBanner = (
    <PrimaryMediaBanner
      type="newsNow"
      theme="dark"
      dataIcid="canonical-video_primary-media-banner-cta"
    />
  );

  return (
    <>
      <Head>
        <IconfontStyleTag />
      </Head>
      <ThemesCssBundle vertical={vertical} />
      <div
        className={classNames(
          globalContainerStyles.container,
          'bg-knockout-primary',
        )}
        id="content"
      >
        <HeaderAndFooter layout={layout}>
          <ErrorBoundary errorHandler={handleMainContentError} errorLogger={logError}>
            <ScrollingAnalytics contentType="video" contentUrl={nowPlaying.url.primary}>
              <GlobalMetadata />
              <VideoMetadata video={nowPlaying} vertical={vertical} />

              <div className="video-page" data-testid="video-page">
                <div className="video-page__video">
                  <div className="video-page__main">
                    <div
                      className="video-page__player"
                      data-testid="video-page__player"
                      ref={(element) => { playerWrapper = element; }}
                    >
                      <VideoPlayer
                        video={nowPlaying}
                        nowPlayingInitiator={nowPlayingInitiator}
                        autoPlay
                        stickyOffset={80}
                        continuousPlay
                        playlist={associatedPlaylists}
                        onAdPlay={onVideoPlay}
                        adPlayButtonPosition="bottomLeft"
                        onVideoPlay={onVideoPlay}
                        onVideoEnd={onVideoSelect}
                        autoplayTestGroup={autoplayTestGroup}
                        vertical={vertical}
                      />
                    </div>
                    {drawer()}
                  </div>
                  <VideoDetails
                    vertical={vertical}
                    video={nowPlaying}
                    social={socialMediaProfiles}
                    relatedArticle={relatedInfo}
                    isChromeless={isChromeless}
                  />
                </div>
                {shouldShowCTA && (
                  <div className="video-page__CTA--mobile">
                    {primaryMediaBanner}
                  </div>
                )}
                <div className="video-page__carousel" data-testid="video-page-playlist">
                  <PlaylistDrawer
                    playlists={mobilePlaylist}
                    nowPlaying={nowPlaying.id}
                    layout={LAYOUT.VERTICAL}
                    onSelect={onVideoSelect}
                    vertical={vertical}
                  />
                </div>

                {hasPlaylister && <Playlister playlists={topPlaylists} />}

                {shouldShowCTA && (
                  <div className="video-page__CTA--desktop">
                    {primaryMediaBanner}
                  </div>
                )}
                {shouldShowBacon ? insertAd(bacon(), 1) : bestOf()}
                {showTaboola()}
              </div>
            </ScrollingAnalytics>
          </ErrorBoundary>
        </HeaderAndFooter>
      </div>

      <AnalyticsLaunchScripts />
      <AdsBundle />
      <UniversalCheckout vertical={vertical} />
      <CommerceAmazonContentInsights />
    </>
  );
};

/**
 * Handles initial data fetching for the video page
 */
VideoPage.getInitialProps = async (ctx) => {
  const {
    req: {
      params: {
        id,
        playlistId,
      },
    },
    res: {
      locals: {
        vertical,
      },
    },
    store,
  } = ctx;

  const promises = [
    store.dispatch(loadVideoAction(id, vertical)),
    store.dispatch(setPageView(pageView)),
  ];
  // Load playlist for BestOfPlaylist component below player
  const vertTopPlaylists = VERT_TOP_PLAYLISTS[vertical];

  if (vertTopPlaylists) {
    vertTopPlaylists.forEach((topPlaylistId) => {
      promises.push(store.dispatch(loadTopPlaylist(topPlaylistId)));
    });
  }
  // View playlist
  if (playlistId) {
    promises.push(
      store.dispatch(loadAssociatedPlaylistAction(playlistId)),
    );
  }

  await Promise.all(promises);

  const videoStateAfterLoad = store.getState().video;
  const statusCode = videoStateAfterLoad?.current?.error?.status ?? 200;

  setLinkHeaders(ctx.res, vertical);

  return {
    navigating: false,
    pageView,
    statusCode,
  };
};

export default navbar(navbarConfig)(
  connect(mapStateToProps, mapActionsToProps)(VideoPage),
);

export { VideoPage as UnwrappedVideoPage };

